.footer {
    position: fixed;
    line-height: 5vh;
    width: 100%;
    left: 0;
    bottom: 0;
}

div.contact {
    border-top: 1px solid gray;
    display: table;
    width: 100%;
    table-layout: fixed;    /* For cells of equal size */
}
div.contact span {
    font-size: 1em;
    display: table-cell;
    text-align: center;
    vertical-align: middle;
}

@media only screen and (max-width: 500px) {
    div.contact span {
        display: block;
    }
}