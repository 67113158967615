.container {
    position: fixed;
    height: 100%;
    width: 100%;
    top: 50px;
    left: 50px;

    display: flex;
    flex-flow: column;

    h1 {
        margin-top: 0px;
        font-size: 4vw;
    }
}

.links {
    margin-bottom: 20%;
}

.link-section {
    width: 100px;

    span {
        display: block;
        width: 100px;
        padding: 5px;
        margin: 50px 0;
        text-align: center;
        border-bottom: 1px solid gray;
        cursor: pointer;

        svg {
            height: 1em;
            vertical-align: middle;
        }
    }
}

a {
    color: inherit;
    text-decoration: none;
}

.icon {
    position: relative;
    max-height: 20px;
}

@media only screen and (max-height: 700px) {
    .link-section span {
        margin: 10px;
    }
}