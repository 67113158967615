//Dimensions
$height: 100%;
$width: 100%;

//Colors
$colorA: rgba(236, 240, 241, 0.6);
$colorB: rgba(236, 240, 241, 0);

@import url('https://fonts.googleapis.com/css2?family=Archivo:wght@400;700&display=swap');

body {
  font-family: "Archivo", sans-serif;
  height: $height;
  margin: 0;

  //Positioning the main divs
  div.background {
    background-position: center center;
    height: $height;
    width: $width;
    position: fixed;
    top: 0;
    pointer-events: none;
  }

  #picture {
    background-image: url("./assets/background-image.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    z-index: -1;
  }

  #layer1 {
    background: repeating-linear-gradient(90deg, $colorB, $colorA, $colorB);
    animation: layer1 10s linear infinite alternate;
  }

  #layer2 {
    background: repeating-linear-gradient(90deg, $colorB, $colorA, $colorB);
    animation: layer2 15s linear infinite alternate;
  }

  //Animations
  @keyframes layer1 {
    0% {background-position: 1000px 0;}
    75% {background-position: 500px 0;}
    100% {background-position: 0 0;}
  }
  @keyframes layer2 {
    0% {background-position: 0 0%;}
    75% {background-position: 500px 0%;}
    100% {background-position: 1000px 0;}
  }
}
